<template>
  <v-container
    fluid
    class="profile-update-container scroll-container"
    v-if="userData"
  >
    <CategoryTitle :category="category" />

    <h2 class="form-title px-0 px-sm-3" v-html="$t('profile.isCardOwner')" />
    <UserData
      ref="personalDataForm"
      :userData.sync="userData"
      :supportData.sync="supportData"
      :hasFidelityCard="true"
      :askFirstName="true"
      :askLastName="true"
      :askFiscalCode="true"
      :isProfileForm="true"
      :showButtons="false"
      @validate="validate"
    />
    <h2
      class="form-title px-0 px-sm-3"
      v-html="`${$t('profile.header.address')} *`"
    />

    <UserAddress
      ref="addressDataForm"
      :userData.sync="userData"
      :supportData.sync="supportData"
      :isProfileForm="true"
      :showButtons="false"
      @validate="validate"
    />

    <!-- <h2 class="form-title px-0 px-sm-3" v-html="`${$t('profile.header.contacts')} *`" /> -->

    <h2
      id="privacy"
      class="form-title px-0 px-sm-3"
      v-html="`${$t('profile.header.privacy')} *`"
    />

    <UserPasswordAndPrivacy
      ref="privacyDataForm"
      :userData.sync="userData"
      :supportData.sync="supportData"
      :isProfileForm="true"
      :showButtons="false"
      :showPasswordFields="false"
      :response="{ status: 0 }"
      @validate="validate"
    />

    <v-layout
      :column="$vuetify.breakpoint.xs"
      :row="$vuetify.breakpoint.smAndUp"
      class="d-block align-sm-center px-0 px-sm-3 mb-7 mt-3"
    >
      <v-row
        no-gutters
        justify="space-between"
        justify-sm="start"
        class="mt-5 mt-sm-0"
      >
        <v-col cols="5" class="px-0">
          <v-btn
            x-large
            depressed
            block
            class="primary--text"
            v-html="$t('common.back')"
            @click="back"
          />
        </v-col>
        <v-col cols="5" class="px-0">
          <v-btn
            color="primary"
            x-large
            depressed
            block
            v-html="$t('common.next')"
            @click="confirm"
            :disabled="!valid"
          />
        </v-col>
      </v-row>
      <label v-html="$t('profile.mandatoryField')" class="d-block pl-2 mb-5" />
    </v-layout>

    <div class="anonymize-block mt-10 primary--text px-0 px-sm-3">
      <h2 class="form-title" v-html="$t('profile.header.anonymizeAccount')" />
      <span
        >Tramite questa funzione il tuo account verrà eliminato e non potrai più
        fare ordini<br />
        L'opzione è disponibile solo se non hai ordini da ritirare
      </span>
      <ResponseMessage class="mt-3" :response="responseAnonymazeAccount" />
      <div no-gutters class="d-flex justify-center justify-md-end mt-3 mt-sm-6">
        <v-btn
          large
          color="secondary"
          depressed
          min-width="250"
          class="text-uppercase mb-2"
          @click="anonymizeAccount()"
          :loading="loadingAnonymizeAccount"
          >{{ $t("profile.anonymizeAccountBtn") }}
        </v-btn>
      </div>
    </div>

    <v-dialog v-model="removeDialog" max-width="400">
      <div class="vuedl-layout__closeBtn" @click="removeDialog = false">
        ×
      </div>
      <v-card>
        <v-card-title class="anonymize-dialog-title text-h2">
          {{ $t("profile.anonymizeProfileDialog.title") }}
        </v-card-title>
        <v-card-text
          class="anonymize-dialog-text text-body-1"
          style="font-size: 14px !important"
          v-html="$t('profile.anonymizeProfileDialog.text')"
        >
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="primary"
            outlined
            @click.prevent="anonymizeAccount(true)"
            @mousedown.stop
          >
            {{ $t("common.yes") }}
          </v-btn>
          <v-btn class="primary" depressed text @click="removeDialog = false">
            {{ $t("common.no") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<style lang="scss">
.profile-update-container {
  h2 {
    font-size: 30px;
  }
  div[class*="col-"] {
    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      padding: 0 12px !important;
    }
  }
  a {
    color: var(--v-default-base);
  }
  .v-autocomplete {
    box-shadow: none;
    -webkit-box-shadow: none;
    input {
      font-size: 16px !important;
    }
  }
}
</style>
<script>
import { requiredValue, isEmail, isNumber } from "~/validator/validationRules";

import CategoryTitle from "@/components/category/CategoryTitle.vue";
import UserData from "@/components/profile/form/UserData.vue";
import UserAddress from "@/components/profile/form/UserAddress.vue";
import UserPasswordAndPrivacy from "@/components/profile/form/UserPasswordAndPrivacy.vue";
import ConfirmPrivacyOptions from "@/components/profile/form/ConfirmPrivacyOptionsModal.vue";
import ResponseMessage from "@/components/ResponseMessage.vue";

import categoryMixins from "~/mixins/category";
import recaptcha from "~/mixins/recaptcha";

import { setPersonInfo } from "~/service/ebsn";
import TigrosRegistrationService from "@/service/tigrosRegistrationService";
import UserService from "~/service/userService";

import { mask } from "vue-the-mask";
import get from "lodash/get";

export default {
  name: "ProfileUpdate",
  components: {
    CategoryTitle,
    UserData,
    UserAddress,
    UserPasswordAndPrivacy,
    ResponseMessage
  },
  mixins: [categoryMixins, recaptcha],
  directives: { mask },
  data() {
    return {
      valid: true,
      birthdateMenu: false,
      requiredRules: requiredValue,
      requiredValue: requiredValue,
      emailRules: [requiredValue("Digitare l'e-mail"), isEmail()],
      dateRules: [requiredValue("Digitare la data di nascita")],
      isEmail: isEmail,
      isNumber: isNumber,
      fidelityCardRules: [
        requiredValue(),
        isNumber("Deve contenere solo cifre")
      ],
      userData: {
        person: {},
        contact: {},
        billingAddress: {}
      },
      supportData: {
        privacy1: true,
        privacy2: true,
        country: {
          itemId: null
        }
      },
      provinces: [],
      response: {},
      loadingAnonymizeAccount: false,
      removeDialog: false,
      responseAnonymazeAccount: {}
    };
  },
  computed: {
    birthDate: {
      get: function() {
        if (this.userData.person.birthDate) {
          return this.$dayjs(this.userData.person.birthDate).format(
            "DD/MM/YYYY"
          );
        } else {
          return null;
        }
      },
      set: function(value) {
        this.userData.person.birthDate = this.$dayjs(
          value,
          "DD/MM/YYYY"
        ).format("YYYY-MM-DD");
      }
    }
  },
  methods: {
    toggleShowPassword(e) {
      e.preventDefault();
      this.showPassword = !this.showPassword;
    },
    async getUserData() {
      const result = await UserService.getUserDetail();

      if (result) {
        this.userData = result;
        // workaround per forzare il popolamento del campo country
        let country = global.config.customCountries.find(c => {
          return c.itemId === this.userData.billingAddress.countryId;
        });
        this.$refs.addressDataForm.changeCountry(country, false);

        // workaround per allineare le v-radio della privacy nel form UserPasswordAndPrivacy
        let privacy1 = this.userData.person.personInfos.find(i => {
          return i.personInfoTypeId === "7";
        }).value;

        this.supportData.privacy1 =
          typeof privacy1 === "string" ? privacy1 === "true" : privacy1;

        let privacy2 = this.userData.person.personInfos.find(i => {
          return i.personInfoTypeId === "8";
        }).value;

        this.supportData.privacy2 =
          typeof privacy2 === "string" ? privacy2 === "true" : privacy2;
      }
    },
    validate() {
      let personalData = this.$refs.personalDataForm.$refs.form;
      let addressData = this.$refs.addressDataForm.$refs.form;
      let privacyData = this.$refs.privacyDataForm.$refs.form;
      let _this = this;
      setTimeout(function() {
        _this.valid =
          personalData.validate() &&
          addressData.validate() &&
          privacyData.validate();
      }, 200);
    },
    back() {
      this.$router.back();
    },
    async confirm() {
      if (
        this.supportData.privacy1 !== true ||
        this.supportData.privacy2 !== true
      ) {
        let _this = this;
        let res = await _this.$dialog.show(ConfirmPrivacyOptions, {
          waitForResult: true,
          width: 500
        });
        if (res) {
          _this.updateProfile();
        }
      } else {
        this.updateProfile();
      }
    },
    async updateProfile() {
      let _this = this;
      // for (let i = 0; i < this.userData.person.personInfos.length; i++) {
      //   if (this.userData.person.personInfos[i].personInfoTypeId === "7") {
      //     this.userData.person.personInfos[i].value = this.supportData.privacy1;
      //   }
      //   if (this.userData.person.personInfos[i].personInfoTypeId === "8") {
      //     this.userData.person.personInfos[i].value = this.supportData.privacy2;
      //   }
      // }
      setPersonInfo(this.userData.person, 7, this.supportData.privacy1);
      setPersonInfo(this.userData.person, 8, this.supportData.privacy2);

      if (typeof this.userData.billingAddress.province === "object") {
        this.userData.billingAddress.province = this.userData.billingAddress.province.name;
      }
      try {
        _this.response = {};
        let res = await TigrosRegistrationService.updateUserDetail(
          _this.userData
        );
        if (res) {
          // this.loadCart();
        }
      } catch (response) {
        _this.response = response;
      }
    },
    async anonymizeAccount(perfom = false) {
      this.removeDialog = true;
      if (perfom) {
        this.loadingAnonymizeAccount = true;
        let result = await UserService.anonymizeAccount();
        if (result.response.status === 0) {
          this.removeDialog = false;
          // se utente davvero anonimizzato -> logout
          await this.doLogout();
          this.loadingAnonymizeAccount = false;
          this.$router.push({ name: "HomeSite" });
        } else {
          this.responseAnonymazeAccount = result.response;
          this.removeDialog = false;
          this.loadingAnonymizeAccount = false;
        }
      }
    }
  },
  async mounted() {
    let newEmailToken = get(this, "$route.query.emailchanged", null);
    if (newEmailToken) {
      await UserService.updateEmail(newEmailToken);
    }
    await this.getUserData();
    this.validate();
    if (this.$route.query.anchor) {
      this.$vuetify.goTo(this.$refs[this.$route.query.anchor]);
    }
  }
};
</script>
