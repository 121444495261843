<template>
  <v-form
    class="user-address-form"
    v-model="valid"
    ref="form"
    :key="componentKey"
  >
    <v-row no-gutters>
      <!-- ADDRESS1 -->
      <v-col cols="12" sm="8">
        <label v-html="`${$t('profile.address.address')} *`" />
        <v-text-field
          :placeholder="`${$t('profile.address.address')} *`"
          v-model="userData.billingAddress.address1"
          :rules="[requiredRules()]"
          outlined
          dense
          @change="validate"
        ></v-text-field>
      </v-col>
      <!-- ADDRESS NUMBER -->
      <v-col cols="12" sm="2">
        <label v-html="`${$t('profile.address.addressNumber')} *`" />
        <v-text-field
          :placeholder="`${$t('profile.address.addressNumber')} *`"
          v-model="userData.billingAddress.addressNumber"
          :rules="[requiredRules()]"
          outlined
          dense
          @change="validate"
        ></v-text-field>
      </v-col>
      <!-- POSTAL CODE -->
      <v-col cols="12" sm="2">
        <label v-html="`${$t('profile.address.postalcode')} *`" />
        <v-text-field
          :placeholder="`${$t('profile.address.postalcode')} *`"
          v-model="userData.billingAddress.postalcode"
          :rules="[postalCodeRules]"
          required
          outlined
          dense
          @input="capChanged(userData.billingAddress.postalcode)"
        ></v-text-field>
      </v-col>
      <!-- COUNTRY -->
      <v-col cols="12" sm="4">
        <label v-html="`${$t('profile.address.country')}`" />
        <v-select
          :placeholder="`${$t('profile.address.country')}`"
          v-model="supportData.country"
          :items="customCountries"
          return-object
          item-text="name"
          item-value="itemId"
          required
          outlined
          dense
          @change="changeCountry($event, true)"
        />
      </v-col>
      <!-- PROVINCE -->
      <v-col
        cols="12"
        sm="4"
        v-if="supportData.country && supportData.country.iso === 'IT'"
      >
        <label v-html="`${$t('profile.address.province')}`" />
        <v-combobox
          :placeholder="`${$t('profile.address.searchProvince')} *`"
          v-model="userData.billingAddress.province"
          :rules="[requiredRules()]"
          :items="getProvincesList(userData.billingAddress.province)"
          append-icon=""
          height="40"
          outlined
          dense
          item-text="name"
          item-value="name"
          @change="changeProvince"
        ></v-combobox>
      </v-col>
      <!-- CITY (Italy) -->
      <v-col
        cols="12"
        sm="4"
        v-if="supportData.country && supportData.country.iso === 'IT'"
      >
        <label v-html="`${$t('profile.address.city')}`" />
        <v-text-field
          :placeholder="`${$t('profile.address.city')} *`"
          v-model="userData.billingAddress.city"
          :rules="[requiredRules()]"
          outlined
          dense
          :disabled="
            !userData.billingAddress.postalcode ||
              !citiesUnderCap ||
              citiesUnderCap.length <= 0
          "
          @change="validate"
        ></v-text-field>
      </v-col>

      <!-- CITY (Switzerland) -->
      <v-col
        cols="12"
        sm="4"
        v-if="supportData.country && supportData.country.iso === 'CH'"
      >
        <label v-html="`${$t('profile.address.city')}`" />
        <v-text-field
          :placeholder="`${$t('profile.address.city')} *`"
          v-model="userData.billingAddress.city"
          :rules="[requiredRules()]"
          outlined
          dense
          @change="validate"
        ></v-text-field>
      </v-col>
    </v-row>

    <!-- pulsanti di annullamento e conferma non visibili nel profilo utente -->
    <v-layout
      v-if="showButtons"
      :column="$vuetify.breakpoint.xs"
      :row="$vuetify.breakpoint.smAndUp"
      class="align-sm-center"
    >
      <v-row
        no-gutters
        justify="space-between"
        justify-sm="start"
        class="mt-5 mt-sm-0"
      >
        <v-col cols="5">
          <v-btn
            x-large
            depressed
            block
            class="primary--text"
            v-html="$t('common.back')"
            @click="back"
          />
        </v-col>
        <v-col cols="5">
          <v-btn
            color="primary"
            x-large
            depressed
            block
            v-html="$t('common.next')"
            @click="submit"
            :disabled="!valid"
          />
        </v-col>
      </v-row>

      <label v-html="$t('profile.mandatoryField')" />
    </v-layout>
  </v-form>
</template>

<style lang="scss">
.user-address-form {
  .v-autocomplete {
    input,
    ::placeholder {
      font-size: 16px !important;
    }
  }
  .v-select {
    .v-select__selection {
      color: rgba(0, 0, 0, 0.87) !important;
      font-weight: normal;
    }
  }
  .v-input--is-disabled {
    input {
      color: rgba(0, 0, 0, 0.87);
    }
  }
  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    [class*=" col-"] {
      padding: 0 12px !important;
    }
  }
}
</style>

<script>
import { requiredValue } from "~/validator/validationRules";
import TigrosService from "@/service/tigrosCustomService";
export default {
  name: "UserDataForm",
  props: {
    isProfileForm: { type: Boolean, default: false },
    userData: { type: Object, required: true },
    supportData: {
      type: Object,
      required: true
    },
    ticketUpdateMode: { type: Boolean, default: false },
    showButtons: { type: Boolean, default: true }
  },
  data() {
    return {
      customCountries: global.config.customCountries,
      citiesUnderCap: [],
      provinceList: [],
      requiredRules: requiredValue,
      valid: false,
      componentKey: 0
    };
  },
  methods: {
    postalCodeRules(v) {
      if (!v) return false;
      if (this.supportData.country) {
        let r =
          this.supportData.country.iso === "CH"
            ? new RegExp(/^[1-9][0-9][0-9][0-9]$/)
            : new RegExp(/^[0-9]{5}$/);
        return (
          !v ||
          r.test(v) ||
          "Il codice postale non è valido per il paese selezionato"
        );
      }
      return false;
    },
    getProvinces(country, homeDeliveryOnly) {
      let _this = this;
      if (_this.provinceList.length < 1) {
        TigrosService.provinceList(country.itemId, homeDeliveryOnly).then(
          function(results) {
            _this.provinceList = results;
          }
        );
      }
    },
    getProvincesList(text) {
      let _this = this;
      var filteredProvinces = [];
      let insertedText = text?.name || text;
      if (insertedText) {
        var formattedText = insertedText.toLowerCase();
        for (var i = 0; i < _this.provinceList.length; i++) {
          var formattedProvinceName = _this.provinceList[i].name.toLowerCase();
          if (
            (formattedText.length == 1 &&
              formattedProvinceName[0] == formattedText) ||
            (formattedText.length > 1 &&
              formattedProvinceName.includes(formattedText))
          ) {
            filteredProvinces.push(_this.provinceList[i]);
          }
        }
      } else {
        filteredProvinces = _this.provinceList;
      }
      return filteredProvinces;
    },
    capChanged(cap) {
      this.userData.billingAddress.city = null;
      let _this = this;
      if (typeof cap !== "undefined") {
        TigrosService.searchCitiesFromCap(cap).then(data => {
          _this.citiesUnderCap = typeof data === "object" ? data : [];
          if (this.citiesUnderCap.length == 1) {
            _this.userData.billingAddress.city = _this.citiesUnderCap[0].name;
            _this.changeProvince({ name: _this.citiesUnderCap[0].province });
          }
        });
      } else {
        this.citiesUnderCap = [];
      }
      this.validate();
    },
    changeProvince(province) {
      this.userData.billingAddress.province = province;
    },
    changeCountry(country, resetAll) {
      if (resetAll) {
        this.userData.billingAddress = {
          addressId: this.userData.billingAddress.addressId
        };
        this.valid = false;
      }
      this.userData.billingAddress.countryId = country.itemId;
      this.supportData.country = country;
      this.validate();
      this.componentKey++;
      //TODO: ???
      // if (country.countryId == 1) {
      //   vm.geoParams = null;
      // } else {
      //   vm.geoParams = {
      //     components: "country:" + country.iso
      //   };
      // }
    },
    validate() {
      let _this = this;
      setTimeout(function() {
        if (_this.$refs.form) {
          _this.valid = _this.$refs.form.validate();
          if (_this.isProfileForm) {
            _this.$emit("validate", _this.valid);
          }
        }
      }, 200);
    },
    back() {
      this.$emit("back");
    },
    submit() {
      this.$emit("submit", this.supportData);
    }
  },
  async mounted() {
    await this.getProvinces(this.customCountries[0], false);
    if (!this.isProfileForm) {
      this.changeCountry(this.customCountries[0]);
    }
  }
};
</script>
